<template>
  <div>
    <CCard>
      <CNav v-if="transit" variant="tabs">
        <CNavItem
          v-for="transaction in transit.lastTransactions"
          :key="transaction.id"
          :active="currentTransactionId == transaction.id"
          @click.native.stop="currentTransactionId = transaction.id">
          {{ transaction.productOwner.name }} x {{ transaction.quantity }}
        </CNavItem>
      </CNav>
      <CElementCover
        v-if="loading"
        :opacity="0.8"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
        />
      </CElementCover>
      <template v-else>
        <CCardBody>
          <CInput
            label="Товар"
            disabled
            :value="currentTransaction.productName"
          />
          <CRow>
            <CCol col="6">
              <CInput
                label="Артикул"
                disabled
                :value="currentTransaction.sku"
              />
            </CCol>
            <CCol col="6">
              <CInput
                label="Кол-во"
                disabled
                :value="currentTransaction.quantity"
              />
            </CCol>
          </CRow>
          <CInput
            label="Сейчас находится у"
            disabled
            :value="currentTransaction.productOwner.name"
          />
          <div class="form-group" v-if="currentTransaction.document">
            <label>Ссылка на документ</label>
            <div>
              <CLink
                :href="documentLink"
                target="_blank"
              >
                Документ №{{ currentTransaction.document.number }}
              </CLink>
            </div>
          </div>
        </CCardBody>
      </template>
    </CCard>
    <CCard>
      <CCardHeader class="mx-4 px-0">
        <h2>История</h2>
      </CCardHeader>
      <CElementCover
        v-if="loading"
        :opacity="0.8"
      >
        <CSpinner
          class="custom-spinner"
          color="info"
        />
      </CElementCover>
      <template v-else>
        <CCardBody>
          <ul class="timeline">
            <history-item v-for="item in history" :key="item.id" :item="item" />
          </ul>
        </CCardBody>
      </template>
    </CCard>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import HistoryItem from './history-item';

export default {
  name: 'ShowTransit',
  components: {
    HistoryItem
  },
  data() {
    return {
      transit: null,
      currentTransactionId: null,
      history: [],
      loading: false,
      loadingHistory: false,
    }
  },
  mounted() {
    this.loadTransit();
  },
  watch: {
    'currentTransactionId': function(id) {
      this.loadHistory(id);
    }
  },
  computed: {
    transitId() {
      return this.$route.params.id
    },
    currentTransaction() {
      if (!this.transit) {
        return null;
      }
      return this.transit.lastTransactions.find(el => el.id == this.currentTransactionId)
    },
    documentLink() {
      const {document} = this.currentTransaction;
      if (!document) {
        return null;
      }
      const route = this.$router.resolve({
        name: 'admin.documents.info',
        params: { id: document.id },
      });
      console.log(route);
      return route.href;
    }
  },
  methods: {
    async loadTransit() {
      try {
        this.loading = true;
        const {data} = await this.$axios.get(`/api/transits/${this.transitId}`);
        this.transit = data;
        this.currentTransactionId = this.transit.lastTransactions[0].id
      } catch(e) {
        console.log(e);
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Что-то пошло не так!',
        }).then(() => {
          this.$router.replace({name: 'admin.transits.index'});
        })
      } finally {
        this.loading = false;
      }
    },
    async loadHistory(transitId) {
      try {
        this.loadingHistory = true;
        const {data} = await this.$axios.get(`/api/transits/${this.transitId}/history`, { params: {transactionId: transitId} });
        this.history = data;
      } catch(e) {
        console.log(e);
      } finally {
        this.loadingHistory = false;
      }
    }
  }
}
</script>

<style lang="scss">
  .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;

    &:before {
        background-color: var(--light);
        bottom: 0;
        content: " ";
        left: 22px;
        position: absolute;
        top: 0;
        width: 3px;
    }
  }
</style>
