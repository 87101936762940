<template>
  <li class="history-item d-flex align-items-center">
    <div
      class="mr-4 circle d-flex align-items-center justify-content-center"
      :class="{'active': isActive}"
    >
      <CIcon name="cil-truck" />
    </div>
    <CCard body-wrapper class="flex-grow-1 mb-0">
      <div>
        <span v-if="item.ownershipStartedAt">
          Переход прав к <strong>{{ item.productOwner.name }}</strong> от {{ ownershipStartedFormatted }}
        </span>
        <span v-else>
          Товар был у <strong>{{ item.productOwner.name }}</strong>
        </span>
      </div>
      <div>
        В количестве <strong>{{ item.quantity }} шт.</strong>
      </div>
      <div>
        <span v-if="item.daysInTransit">
          В транзите <strong>{{ item.daysInTransit }}</strong> дн.
        </span>
      </div>
    </CCard>
  </li>
</template>

<script>
import moment from 'moment';

export default {
  name: 'HistoryItem',
  props: {
    item: Object
  },
  computed: {
    isActive() {
      return !this.item.ownershipEndedAt;
    },
    ownershipStartedFormatted() {
      return moment(this.item.ownershipStartedAt).format('DD-MM-YYYY')
    }
  }
}
</script>

<style lang="scss">
  .history-item {
    margin-bottom: 12px;
    position: relative;
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }

    .circle {
      opacity: .7;
      border-color: var(--dark);
      color: white;
      background-color: var(--dark);
      z-index: 3;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      &.active {
        opacity: 1;
        background-color: var(--success);
      }
    }
  }
</style>
